import React, {FC} from 'react';

import janeFace from "../Resources/Faces/JaneFace.png"
import michaelFace from "../Resources/Faces/MichaelFace.png"
import annaFace from "../Resources/Faces/AnnaFace.jpeg";
import stars from "../Resources/Rating.png"

interface FeedbackProps {
    name: string,
    review: string,
    avatar: string
}

const FeedbackCard: FC<FeedbackProps> = (props) => {
    return (
        <div className="text-left self-stretch p-8 bg-neutral-800 rounded-3xl justify-center items-start gap-6 flex max-w-screen-sm h-fit">
            <img className="w-16 h-16 rounded-full" src={props.avatar} />
            <div className="grow basis-0 flex-col justify-start items-start gap-2 inline-flex max-w-screen-sm">
                <div className="self-stretch text-stone-300 text-xl font-bold --webkit-font-['Onest'] leading-normal">{props.name}</div>
                <img className="w-32" src={stars}/>
                <div className="self-stretch text-zinc-100 text-lg font-normal --webkit-font-['Onest'] leading-normal">{props.review}</div>
            </div>
        </div>
    );
};

const FeedbackBlock = () => {
    return (
        <div className="flex flex-col bg-neutral-900 justify-start items-start gap-4 w-full px-8 xl:px-48 py-8">
            <div className="justify-start items-center">
                <div className="text-left text-zinc-100 text-5xl font-bold --webkit-font-['Unbounded'] leading-10">What are people saying about us?</div>
            </div>
            <p className="text-left text-zinc-500 text-2xl font-medium --webkit-font-['Onest'] leading-loose">Over 5000 users rated our Deepswap face swap app on all major platforms.</p>
            <div className="self-center gap-6 flex flex-col lg:flex-row">
                <FeedbackCard
                    name={"Jane P."}
                    review={"I like it a lot. This app is very fast, VERY fast. It has conveniently located tools, it has cool machine learning auto-processing."}
                    avatar={janeFace}
                />
                <FeedbackCard
                    name={"Anna S."}
                    review={"Easy to use and the quality is great. The AI does a fantastic job of recognizing both the base face and the source face. I even paid for a three month subscription. (I rarely pay for app subscriptions)"}
                    avatar={annaFace}
                />
                <FeedbackCard
                    name={"Michael L."}
                    review={"Quality is amazing, worth the money for sure if you’re just doing video. But it really needs the ability to do still images and gifs. "}
                    avatar={michaelFace}
                />
            </div>
        </div>
    );
};

export default FeedbackBlock;