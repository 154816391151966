import React, {FC} from 'react';

interface HeaderProps {
    onFeaturesClick: () => void,
    onStepsClick: () => void,
    onFeedbackClick: () => void,
    onDownloadClick: () => void
}

const HeaderPanel: FC<HeaderProps> = (props) => {
    const classStyle =
        "text-center cursor-pointer hover:text-purple-500 text-zinc-100 text-sm md:text-xl font-medium --webkit-font-['Onest'] leading-normal";

    return (
        <div className="bg-neutral-900 text-white w-full h-full">
            <div className="flex space-x-8 justify-center">
                <p
                    className={classStyle}
                    onClick={ () => {
                        props.onFeaturesClick()
                    }}
                >
                    Features
                </p>
                <p
                    className={classStyle}
                    onClick={ () => {
                        props.onStepsClick()
                    }}
                >
                    Steps
                </p>
                <p
                    className={classStyle}
                    onClick={ () => {
                        props.onFeedbackClick()
                    }}
                >
                    User feedback
                </p>
                <p
                    className={classStyle}
                    onClick={ () => {
                        props.onDownloadClick()
                    }}
                >
                    Download
                </p>
            </div>
            <div className="left-[24px] top-[12px] absolute justify-start items-center invisible md:visible">
                <div className="font-extrabold text-transparent text-3xl bg-clip-text bg-gradient-to-r from-pink-400 to-purple-500 --webkit-font-['Unbounded'] leading-10">AI Face</div>
            </div>
        </div>
    );
};

export default HeaderPanel;