import {ConfigProvider} from "antd";
import './App.css';
import {Route, BrowserRouter, Routes} from "react-router-dom";
import LandingPage from "./Pages/LandingPage";
import React from 'react';

function App() {
  return (
      <ConfigProvider
          theme={{ token: { colorPrimary: '#C559A9' } }}
      >
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route key="landing" path="/" element={<LandingPage />}/>
            </Routes>
          </BrowserRouter>
        </div>
      </ConfigProvider>
  );
}

export default App;
